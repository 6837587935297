import * as React from 'react'

import Helmet from 'react-helmet'
import * as _ from 'lodash'
import Header from '../components/Header'
//import * as SegmentAnalytics from '../lib/segment'
import { getValidLangKey } from 'ptz-i18n'
import PopularSearchesSection from '../components/sections/PopularSearchesSection'
import PageDefault from '../components/Page'
import styled, { ThemeProvider } from 'styled-components'
import { bgColorMapping } from '../styles/config'
import DynamicAssembly from '../components/DynamicAssembly'
import IndexLayout from '../components/layout'
import { graphql } from 'gatsby'
import { RouteComponentProps, LocationContext } from '@reach/router'
import {
  Container,
  media,
  Row,
  Col,
  themes,
  LanguageContext,
  gridLgSpacing,
} from 'cc-ui-components'

interface WebpageProps {
  data: {
    site: {
      siteMetadata: {
        languages: {
          defaultLangKey: string
          langs: string[]
        }
      }
    }
    contentfulWebpage: {
      title: string
      description: {
        description: string
      }
      noIndex: boolean
      image: any
      slug: string
      node_locale: string
      modules: any
    }
  }
}

const SectionCol = styled(Col)`
  & > * {
    margin-top: 80px;
    &:first-child {
      margin-top: 0;
    }
  }
  ${media.sm`
   & > * {
      margin-top: 30px;
    }
    padding: 0 20px;
  `};
`

const Page = styled(PageDefault)`
  ${media.sm`
    & > div {
      & > div > div {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  `};
`

class WebpageTemplate extends React.Component<
  WebpageProps & RouteComponentProps & LocationContext
> {
  /*componentDidMount(): void {
    const title = _.get(this.props.data.contentfulWebpage, 'title')
    requestAnimationFrame(() => {
      SegmentAnalytics.page(`Webpage / ${title}`)
    })
  }*/

  public render() {
    const {
      langs,
      defaultLangKey,
    } = this.props.data.site.siteMetadata.languages
    const langKey = getValidLangKey(
      langs,
      defaultLangKey,
      this.props.data.contentfulWebpage.node_locale
    )
    const modules: ContentfulModule[] =
      _.get(this.props.data.contentfulWebpage, 'modules') || []
    const headerModules: any = modules.filter(
      module => module.__typename === 'ContentfulModulePageHeader'
    )
    const sectionModules = modules.filter(
      module => module.__typename === 'ContentfulModuleSection'
    )
    const fullWidth = modules.filter(
      module => module.__typename === 'ContentfulModuleImageFullwidth'
    )

    const headerColor = _.get(headerModules, '0.type', 'regular')

    const title = _.get(this.props.data.contentfulWebpage, 'title')

    const description = _.get(
      this.props.data.contentfulWebpage,
      'description.description'
    )
    const image = _.get(this.props.data.contentfulWebpage, 'image.file.url')
    const noIndex = _.get(this.props.data.contentfulWebpage, 'noindex', false)
    const hideFooter = _.get(
      this.props.data.contentfulWebpage,
      'hideFooter',
      false
    )

    return (
      <LanguageContext.Provider value={langKey}>
        <IndexLayout location={this.props.location} hideFooter={hideFooter}>
          <Helmet htmlAttributes={{ lang: langKey }}>
            <title>{title} </title>
            <meta name="description" content={description} />
            {noIndex && <meta name="ROBOTS" content="NOINDEX,NOFOLLOW" />}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:locale" content={langKey} />
            {image && <meta property="og:image" content={image} />}
          </Helmet>
          <Header
            dark={headerColor === 'dark'}
            light={headerColor === 'light'}
          />
          {headerModules.length > 0 && (
            <ThemeProvider theme={themes[headerModules[0].type || 'regular']}>
              {DynamicAssembly(headerModules)}
            </ThemeProvider>
          )}
          {fullWidth.map((module: any, index: number) => (
            <React.Fragment key={`fullWidth-${index}`}>
              {DynamicAssembly(module)}
            </React.Fragment>
          ))}
          <Page>
            {sectionModules.map((contentModule: any, keyModule: number) => {
              const colorMode = contentModule.type || 'regular'
              const theme = themes[colorMode]
              return (
                <ThemeProvider theme={theme} key={`SectionModule_${keyModule}`}>
                  <Container
                    padingsVertical={['90px', '90px']}
                    backgroundColor={bgColorMapping[colorMode]}
                    id={contentModule.id}
                  >
                    <Row>
                      <SectionCol
                        sm={12}
                        md={12}
                        lg={contentModule.narrow ? 10 : gridLgSpacing}
                        center
                      >
                        {DynamicAssembly(contentModule.modules)}
                      </SectionCol>
                    </Row>
                  </Container>
                </ThemeProvider>
              )
            })}
          </Page>
          {!hideFooter && (
            <PopularSearchesSection sm={12} md={12} lg={gridLgSpacing} />
          )}
        </IndexLayout>
      </LanguageContext.Provider>
    )
  }
}

export default WebpageTemplate

export const query = graphql`
  query WebpageTemplateQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulWebpage(id: { eq: $id }) {
      title
      description {
        description
      }
      node_locale
      slug
      noIndex
      image {
        file {
          url
        }
      }
      hideFooter
      modules {
        ...modulePageHeader
        ...moduleSection
        ...moduleFullwidthImage
      }
    }
  }
`
